<template>
    <div>
        <v-row>
            <v-col>
                <service-top-card src="services_individual_top.jpg">
                    <template v-slot:title>
                        <span class="underline--magical">
                            Bonsai for individuals
                        </span>
                    </template>
                    <template v-slot:text>
                        We provide seamless, tailor-made solutions for you. With a wealth of combined
                        experience and contracts with South Africa's best Financial Services Providers, you can rest
                        assured your financial needs are well taken care of at Bonsai.

                    </template>
                    <template v-slot:additional>
                        <v-btn color="primary" large class="mr-2">Get Started</v-btn>
                    </template>
                </service-top-card>
            </v-col>
        </v-row>
        <v-row>
            <template v-for="(product, idx) in products">
                <v-col cols="12" md="6"  :key="`product-ind-${idx}`">
                    <statements-card :icon="product.icon">
                        <template v-slot:title>
                            {{product.title}}
                        </template>
                        <template v-slot:text>
                            {{product.text}}
                        </template>
                    </statements-card>
                </v-col>
            </template>
        </v-row>
        <v-card class="card-shadow mt-10">
            <v-container>
                <v-row>
                    <v-col cols="12" md="12" lg="8">
                        <v-card-title> How do we deliver our services? </v-card-title>
                        <v-card-text class="pb-0">
                            Bonsai combines accredited FAIS agents with cutting-edge technology to help you gain
                            control of your finances and plan for the future. Our platform provides a hybrid advisor assisted
                            and self-service access to your portfolio - meaning you get the best of both worlds and engage on
                            your terms
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="12" lg="4">
                        <v-img src="https://bonsai.demoing.co.za/assets/images/services_individual_how.jpg"
                               class="white--text align-end"
                               cover></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card class="card-shadow mt-10">
            <v-container>
                <v-row>
                    <v-col>
                        <v-card-title> braap </v-card-title>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-carousel cycle continuous height="200">
                            <!--<v-carousel-item v-for="(color, i) in colors" :key="color">
                                <v-sheet height="100%">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <div class="text-h2">Slide {{ i + 1 }}</div>
                                    </v-row>
                                </v-sheet>
                            </v-carousel-item>-->
                        </v-carousel>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
    import ServiceTopCard from './services/service-top-card.vue'
    import StatementsCard from './home/statements-card.vue'
    export default {
        components: {
            ServiceTopCard,
            StatementsCard
        },
        data: () => {
            return {
                tab1: 0,
                tab2: 0,
                products: [
                    {
                        icon: 'dugfwxlj',
                        title: 'Long term insurance',
                        text: 'These products include life insurance, lump-sum disability, severe illness, income protection and more.'
                    },
                    {
                        icon: 'hbavnglv',
                        title: 'Short term insurance',
                        text: 'These products include personal cover for your vehicles, home contents, building, portable possessions, personal accident and injury, personal liability and more.'
                    },
                    {
                        icon: 'czdsqfzq',
                        title: 'Investments',
                        text: ' These products include unit trusts, tax-free savings plans, endowments, retirement annuities, pension & provident preservation funds, offshore investing and more.'
                    },
                    {
                        icon: 'xbsabxuj',
                        title: 'Medical cover',
                        text: 'These products include medical aid plans with or without savings accounts, medical insurance, gap cover and more.'
                    },
                    {
                        icon: 'ufezupnm',
                        title: 'Estate planning',
                        text: ' These products include a Last Will & Testament, Living Will, Trust services and general estate planning.'
                    },
                    {
                        icon: 'djoczfvh',
                        title: 'Rewards',
                        text: 'Get rewarded by making Bonsai your preferred financial advisory firm. For every policy we help you manage, you will receive a monthly cashback paid into your free virtual card account.'
                    }
                ]
            };
        },
    };
</script>
